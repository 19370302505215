var site = site || {};
var profileRequests = profileRequests || '';
site.profile = site.profile || {};

/* Analytics reporting to be read post page load */
if (typeof tms_page_data =='object'){
    tms_page_data['PSN'] = {};
}else{
    tms_page_data = {};
    tms_page_data['PSN'] = {};
}
   

site.profile.products = function() {

  // Helper function to check for personalization on a site.
  var _noPersonalization = function() {
    return !profileRequests || !Drupal.cl.hasPersonalization();
  };

  // Helper function to get the profile events class from /personal_block/js/events.js if available.
  var _getEventClass = function() {
    return (site.profile && site.profile.events) ? site.profile.events() : {};
  };

  var _mpp = {
    prodIds : [],
    init : function(data) {
      if (!data || _noPersonalization()) {
        return null;
      };

      this.data.set(data);

      this.setEventMPP();
      //this.setBadging();
      this.sort();
      this.getCustomSwatches();
    },
    data : {
      prods  : {},
      category : {},
      prodList : [],
      set : function(data) {
        var that    = this;
        var catData   = data.category;
        that.category   = (catData) ? catData : {};
        that.prods    = (catData && catData.products) ? catData.products : {};
        $(that.prods).each(function() {
          that.prodList.push(this.PRODUCT_ID);
        });
      }
    },
    getCustomSwatches : function() {
      var products  = this.data.prods;
      var shadedProd  = [];
      $(products).each( function() {
        if (this.isDisplayable && this.shaded) {
          shadedProd.push(this);
        };
      });
      $(shadedProd).each( function() {
        var prodId = this.PRODUCT_ID;
        profileRequests.push({
          rule   : 'prod_matches_skin_tone_or_color_family_affinity',
          rule_id  : 'ShadeFiltered:' + prodId,
          params   : {
            PROD_CAT_IDS : [prodId]
          },
          callback : function(result) {
            var match = result.getValue('PROD_CAT_MATCHES');
            if (!match[0]) {
              return null;
            };
            if (!site.quickshop.customSwatches) {
              site.quickshop.customSwatches = {};
            };
            site.quickshop.customSwatches[prodId] = match;

            _mpp.setProductImages(prodId, match[0].SKU_ID);
          }
        });
      });
      /* Analytics Adobe */
      tms_page_data['PSN']['CUSTOMSWATCH'] = ['prod_matches_skin_tone_or_color_family_affinity',shadedProd];
    },
    setBadging : function() {
      var prodList = this.data.prodList;
      if (!prodList[0]) {
        return null;
      };
      profileRequests.push({
        rule   : 'prod_matches_skin_type_or_skin_concern',
        rule_id  : 'mppBadge',
        params   : {
          PROD_CAT_IDS : prodList
        },
        callback : function(result) {
          var matches = result.getValue('PROD_CAT_MATCHES');
          if (!matches[0]) {
            return null;
          };
          var limit = 3;
          var count = 0;
          $(matches).each( function() {
            var prodNode = $('li[data-productid="' + this.PRODUCT_ID + '"]');
            var badge = prodNode.find('span.badge, div.badge');
            if ( badge[0] && (count < limit) ) {
              // Don't proceed if new badge is present.
              if (!badge.hasClass('badge_1')) {
                // reset badge class
                badge.attr('class','badge');
                badge.addClass('badge_1');
                badge.find('div.badge-inner').html('Best<br/>For<br/>You');
                count++;
              };
            };
          });
          /* Analytics Adobe */
          tms_page_data['PSN']['CUSTOMBADGE'] = ['prod_matches_skin_type_or_skin_concern',matches];
        }
      });
    },
    setEventMPP : function() {
      var events = _getEventClass();
      if ($.isEmptyObject(events)) {
        return null;
      };
  
      var catData = this.data.category;
      if (catData && catData.CAT_BASE_ID) {
        events.store({ 'VIEWED_MPP_5' : catData.CAT_BASE_ID });
      };
    },
    setProductImages : function(prodId, skuId) {
      // Check for all proper data needed.
      if (!prodId || !skuId) {
        return null;
      };
      var prodNode    = $('li[data-productid="' + prodId + '"]');
      // Since default images don't have the data-skuid attr, use that as the identifier.
      var defaultImgs = prodNode.find('img.product-image').not('[data-skuid]');
      // Find all hidden images with the correct skuId.
      var skuImg      = prodNode.find('img.product-image[data-skuid="'+ skuId +'"]');
      if (!defaultImgs[0] || !skuImg[0]) {
        return null;
      };
      defaultImgs.addClass('hidden');
      skuImg.removeClass('hidden');
    },
    sort : function() {
      var prodList = this.data.prodList;
      var adobematches = '';
      if (!prodList[0]) {
        return null;
      };
      profileRequests.push({
        rule   : 'prod_matches_skin_concern',
        rule_id  : 'mppSort',
        params   : {
          PROD_CAT_IDS : prodList
        },
        callback : function(result) {
          var matches = result.getValue('PROD_CAT_MATCHES');
          adobematches = matches;
          if (!matches[0]) {
            return null;
          };
          var container = $('#mpp-product-grid');
          for (var i = matches.length-1; i >= 0; i--) {
            var prodNode = $('li[data-productid="' + matches[i].PRODUCT_ID + '"]');
            prodNode.prependTo(container);
          }
          //$(matches).each( function() {
          //  var prodNode = $('li[data-productid="' + this.PRODUCT_ID + '"]');
          //  prodNode.prependTo(container);
          //});
          /* Analytics Adobe */
          tms_page_data['PSN']['CUSTOMSORT'] = ['prod_matches_skin_concern',adobematches];
        }
      });
    }
  };

  var _spp = {
    prodIds : [],
    init : function(data) {
      if (!data || _noPersonalization()) {
        return null;
      };

      this.data.set(data);
      this.setEventSPP();
      this.getCustomSwatches();
    },
    data : {
      prod   : {},
      set    : function(data) {
        var products    = data.products;
        _spp.data.prod     = products ? products[0] : {};
      }
    },
    setEventSPP : function() {
      var events = _getEventClass();
      if ($.isEmptyObject(events)) {
        return null;
      };
  
      var prodData = _spp.data.prod;
      if (prodData && prodData.PROD_BASE_ID) {
        events.store({ 'VIEWED_SPP_5' : prodData.PROD_BASE_ID });
      };
    },
    getCustomSwatches : function() {
      var prodData = _spp.data.prod;
      if (!prodData) {
        return null;
      };
      var id = prodData.PRODUCT_ID;
      profileRequests.push({
        rule   : 'prod_matches_skin_tone_or_color_family_affinity',
        rule_id  : 'setSTSwatch',
        params   : {
          PROD_CAT_IDS : [id]
        },
        callback : function(result) {
          var skuIds = result.getValue('PROD_CAT_MATCHES');
          if (!skuIds[0]) {
            return null;
          };
          if (!site.quickshop.customSwatches) {
            site.quickshop.customSwatches = {};
          };
          site.quickshop.customSwatches[id] = skuIds;
          /* Analytics Adobe */
          tms_page_data['PSN']['CUSTOMSWATCHSPP'] = ['prod_matches_skin_tone_or_color_family_affinity',skuIds];
        }
      });
    }
  };

  var _powerCouples = {
    init : function() {
      if (_noPersonalization()) {
        return null;
      };

      this.data.set();
      this.sort();    
      //this.setBadges();
    },
    data : {
      prodIds : [],
      set   : function() {  
        var products  = $('div.product');
        $(products).each( function() {
          var id = this.getAttribute('data-productid');
          if (id) {
            _powerCouples.data.prodIds.push(id);
          };
        });
      }
    },
    setBadges : function() {
      var prods = this.data.prodIds;
      if (!prods[0]) {
        return null;
      };
      profileRequests.push({
        rule   : 'prod_matches_skin_type_or_skin_concern',
        rule_id  : 'pcBadge',
        params   : {
          PROD_CAT_IDS : prods
        },
        callback : function(result) {
          var products = result.getValue('PROD_CAT_MATCHES');
          if (!products[0]) {
            return null;
          };
          $(products).each( function() {
            var prodNodes = $('div[data-productid="' + this.PRODUCT_ID + '"]');
            var badge = prodNodes.find('span.badge');
            if (badge[0]) {
              // Don't proceed if has new badge
              if (!badge.hasClass('badge_1')) {
                // reset badge class
                badge.attr('class','badge');
                badge.addClass('badge_1');
                badge.find('div.badge-inner').html('Best<br/>For<br/>You');
              };
            };
          });
          /* Analytics Adobe */
          tms_page_data['PSN']['CUSTOMBADGESPP'] = ['prod_matches_skin_type_or_skin_concern',products];
        }
      });
    },
    sort : function() {
      var rowContainer = $('.power-couples-formatter-rows');
      var rowNodes   = rowContainer.find('div.promo_product_row');
      var adobematches = '';
      if (!rowNodes[0]) {
        return null;
      };
      var skinConcerns = [];
      rowNodes.each( function() {
        var skinConcern = this.getAttribute('id');
        skinConcerns.push(skinConcern);
      });
      profileRequests.push({
        rule   : 'sort_skin_concerns_for_user',
        rule_id  : 'sortSC',
        params   : {
          PROD_SKIN_CONCERNS : skinConcerns
        },
        callback : function(result) {
          var order = result.getValue('PROD_SKIN_CONCERNS');
          adobematches = order;
          $(order).each( function(index, cat) {
            var row = rowContainer.find('#' + cat);
            row.appendTo(rowContainer);
          });
          /* Analytics Adobe */
          tms_page_data['PSN']['CUSTOMSORTSPP'] = ['sort_skin_concerns_for_user',adobematches];
        }
      });    
    }
  };

  var _quickshop = {
    init : function(data) {
      if (!data || _noPersonalization()) {
        return null;
      };
      this.data = data;
    },
    data : '',
    events : function(id) {
      var events = _getEventClass();
      if (!$.isEmptyObject(events)) {
        if (this.data && id) {
          var skus   = this.data.skus;
          var sku  = $(skus).filter( function(){
            return this.SKU_BASE_ID.toString() === id.toString();
          })[0];
          if (sku) {
            var eventsArray = [];
            // Add all events here
            if (sku.ATTRIBUTE_COLOR_FAMILY) {
              eventsArray.push({'COLOR_FAMILY_RECORD':sku.ATTRIBUTE_COLOR_FAMILY});
            };
            // Set all events to data store
            if (eventsArray[0]) {
              events.store(eventsArray);
            };
          };
        };
      };
    }
  };

  return {
    MPP : {
      init : function(data) {
        _mpp.init(data);
      }
    },
    SPP : {
      init : function(data) {
        _spp.init(data);
      }
    },
    powerCouples : {
      init : function(data) {
        _powerCouples.init(data);
      }
    },
    quickshop : {
      init : function(data) {
        _quickshop.init(data);
      },
      send : function(id) {
        _quickshop.events(id);
      }
    }
  };
}();
